import * as constants from "./constants";
export const initialState = {
    //payments
    isLoading: false,
    data: [],
    error: null,
    //For cart payment
    isPaymentCartLoading:false,
    paymentCart:null,
    paymentCartError:null,
    //For payment
    isPaymentLoading:false,
    payment:null,
    paymentError:null,
};
const paymentsReducer = (state = initialState, action)=> {
    switch (action.type) {
        case constants.PAYMENTS_LIST_REQUESTING:
            return {
                ...state,
                isLoading:true
            };

        case constants.PAYMENTS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action?.payload,
                error: null,
            };

        case constants.PAYMENTS_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.error,
            };


        //Payment cart
        case constants.PAYMENTS_CART_REQUESTING:
            return {
                ...state,
                isPaymentCartLoading: true,
            };

        case constants.PAYMENTS_CART_SUCCESS:
            return {
                ...state,
                isPaymentCartLoading: false,
                paymentCart:action.payload,
                paymentCartError:null,
            };

        case constants.PAYMENTS_CART_FAILED:
            return {
                ...state,
                isPaymentCartLoading: false,
                paymentCart:null,
                paymentCartError:action.error,
            };

        //Payment
        case constants.PAYMENTS_REQUESTING:
            return {
                ...state,
                isPaymentLoading: true,
            };

        case constants.PAYMENTS_SUCCESS:
            return {
                ...state,
                isPaymentLoading: false,
                payment:action.payload,
                paymentError:null,
            };

        case constants.PAYMENTS_FAILED:
            return {
                ...state,
                isPaymentLoading: false,
                payment:null,
                paymentError:action.error
            };
            
            
        default:
            return state;
    }
}
export default paymentsReducer
