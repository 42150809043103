import styled from "styled-components";

export const LayoutAdminWrapper = styled.div`
    background: #F57F01;
  /*  --bg-opacity: 1;
    background-color: #1C3FAA;

    //second
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    padding: 0.75rem 2rem;
    font-family: Roboto;
    font-size: 0.875rem;
    --text-opacity: 1;
    color: #2d3748;
    color: rgba(45, 55, 72, var(--text-opacity));
    //third
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;

    @media (max-width: 639px) {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }*/
`;
