import styled from 'styled-components';

export const NavbarWrapper = styled.div`
  .navbar{
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .lg-recoov-logo{
    width: 130px;
    height: 65px;
  }
  .nav-item{
    margin-left: 17px;
    margin-right: 17px;

    .nav-link{
      color:#212529;
      font-size: 15.5px;
      padding: 5px 0.7rem;
    }
    .nav-link-active{
      background: #F57F01;
      color: #fff;
      border-radius: 20px;
    }
    .nav-link-image{
      width: 20px;
    }


  }
  .dropdown-menu{
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 2;
    border: none;
    box-shadow: 0 12px 35px 0 rgba(16,39,112,.25);
    h6{
      font-weight: bold;
      color: #F57F01;
      font-size: 16px;
    }

    .dropdown-item{
      min-width: 200px;
      padding: 7px 15px;
      border-radius: 10px;
      color:#212529;
      &:hover{
        background: #F57F01;
        color: #fff;
      }
    }
  }
  .lg-recoov-nav-btn-login{
    background: #F57F01;
  }
  .lg-recoov-nav-btn-register{
    background: #00B67A;
  }
  .lg-recoov-nav-btn{
    color:#fff;
    margin: 0 4px;
    font-size: 15px;
    font-weight: bold;
    @media all and (max-width: 553px){
      flex: 0 0 50%;
    }

  }
`;
