import styled from 'styled-components';
export const FooterWrapper = styled.div`
  background-color: #FAFAFA;
  color: #212529;
  padding: 0;
  margin: 0;
  width: 100%;
 
  .lg-recoov-footer-container{
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }
  .lg-recoov-footer-top{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-self: stretch;

    @media all and (max-width: 749px) {
      flex-direction: column;
    }
  }
  .lg-recoov-footer-illustration{
    flex: 0 0 45%;
    margin-top: 1.5rem;
    margin-right: 3rem;
    @media all and (max-width: 749px) {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;

    }

    .lg-recoov-footer-logo-box{
      margin-bottom: 0.5rem;
    }

    .lg-recoov-footer-logo{
      // background: #fff;
      padding: 10px;

      .lg-recoov-footer-logo-image{
        width: 150px;
      }
    }

    .lg-recoov-footer-text-box{

    }
    .lg-recoov-footer-text{
      font-size: 14px;
      text-align: justify;
    }
    .lg-recoov-footer-social-box{
      a{
        color: #009E62;
        text-decoration: none;
      }
      i{
        color: #fff;
      }
    }
    .lg-recoov-footer-social-list{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      li{
        margin-right: 1rem;
      }
    }
  }
  ul li{
    list-style: none;
  }
  .lg-recoov-footer-nav{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    @media all and (max-width: 749px) {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  .lg-recoov-footer-nav-item{
    // width: 48%;
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    // background: orange;

    .lg-recoov-footer-nav-title{
      font-size: 16px;
      margin-bottom: 1rem;
      font-weight: bold;
      //text-transform: uppercase;
    }
    .lg-recoov-footer-nav-list{
      padding: 0;
      margin: 0;
      list-style: none;
        width: inherit;
      .lg-recoov-footer-nav-list-item{
        margin-bottom: 10px;
      }
      .lg-recoov-footer-nav-list-link{
        text-decoration: none;
        color: #212529;
        font-size: 15px;
      }
    }
  }

  .lg-recoov-newsletter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    width: 70%;
    margin: 10px auto;
    padding: 15px;
    border-radius: 10px;
    @media all and (max-width: 1111px){
      width: 90%;
    }
    @media all and (max-width: 889px){
      width: 98%;
    }
    @media all and (max-width: 783px){
      flex-direction: column;
    }


  }
  .lg-recoov-newsletter-box-title{
    margin-right: 1rem;
    @media all and (max-width: 783px){
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
  .lg-recoov-newsletter-title{
    color:#009E62;
    font-size: 20px;
    @media all and (max-width: 783px){
      font-size: 16px;
    }
  }
  .lg-recoov-newsletter-form{
    background: #009E62;
    padding:8px;
    border-radius: 15px;
  }
  .lg-recoov-newsletter-form-input{
    border: none;
    margin: 0;
    padding: 6px 10px;
    background: #009E62;
    color:#fff;
    &:focus {
      border: none;
      outline: none !important;
    }
    &::placeholder{
      color:#fff;
      font-size: 15px;
      @media all and (max-width: 783px){
        font-size: 14px;
      }
    }
  }
  .lg-recoov-newsletter-form-btn{
    margin: 0;
    border: none;
    padding: 7px 10px;
    font-size: 14px;
    border-radius: 8px;
    color:#009E62;
    @media all and (max-width: 783px){
      width: 100%;
    }
  }
`;
