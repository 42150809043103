import * as constants from "./constants";

//logs list
export const  logsListRequest =()=> {
    return { type: constants.LOGS_LIST_REQUESTING}
}

export const  logsListSuccess=(payload)=>{
    return { type:constants.LOGS_LIST_SUCCESS, payload }
}

export const  logsListFailed=(error)=>{
    return { type:constants.LOGS_LIST_FAILED, error }
}

//Paginate LOGS
export const  paginateLogsListRequest =(url)=> {
    return { type: constants.PAGINATE_LOGS_LIST_REQUESTING,url}
}

export const  paginateLogsListSuccess=(payload)=>{
    return { type:constants.PAGINATE_LOGS_LIST_SUCCESS, payload }
}

export const  paginateLogsListFailed=(error)=>{
    return { type:constants.PAGINATE_LOGS_LIST_FAILED, error }
}
