import * as Actions from "./constants";

export const showStripePaymentModal = (payload) => {
    return { type:Actions.SHOW_STRIPE_PAYMENT_MODAL,payload}
}

export const showLoginModal = () => {
    return { type:Actions.SHOW_LOGIN_MODAL}
}

export const hideModal = ()  => {
    return { type:Actions.HIDE_MODAL}
}
