import {combineReducers} from "redux";
import searchReducer from "./search/reducer";
import authReducer from "./auth/reducer";
import documentsReducer from "./documents/reducer";
import cartReducer from "./cart/reducer";
import settingsReducer from "./settings/reducer";
import paymentsReducer from "./payments/reducer";
import modalReducer from "./modal/reducer";
import ApikeyReducer from "./api-key/reducer";
import logsReducer from "./logs/reducer";
export const globalReducers = combineReducers({
    search:searchReducer,
    auth:authReducer,
    documents:documentsReducer,
    cart:cartReducer,
    settings:settingsReducer,
    payments:paymentsReducer,
    modal:modalReducer,
    apikey:ApikeyReducer,
    logs:logsReducer
});

