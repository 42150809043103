import * as constants from "./constants";

//Documents list
export const  documentsListRequest =(payload)=> {
    return { type: constants.DOCUMENTS_LIST_REQUESTING,payload}
}

export const  documentsListSuccess=(payload)=>{
    return { type:constants.DOCUMENTS_LIST_SUCCESS, payload }
}

export const  documentsListFailed=(error)=>{
    return { type:constants.DOCUMENTS_LIST_FAILED, error }
}

//Paginate documents
export const  paginateDocumentsListRequest =(url)=> {
    return { type: constants.PAGINATE_DOCUMENTS_LIST_REQUESTING,url}
}

export const  paginateDocumentsListSuccess=(payload)=>{
    return { type:constants.PAGINATE_DOCUMENTS_LIST_SUCCESS, payload }
}

export const  paginateDocumentsListFailed=(error)=>{
    return { type:constants.PAGINATE_DOCUMENTS_LIST_FAILED, error }
}
