import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const getUser =async (id) =>
    await axios.get(API_URL+`/recouv/users/${id}/` ,{
        headers: authHeader(),
    })

export const getBalance =async (id) =>
    await axios.get(API_URL+`/recouv/get-company/${id}` ,{
        headers: authHeader(),
    })

export const editUser =async (id,payload) =>
    await axios.patch(API_URL+`/recouv/users/${id}/`,payload ,{
        headers: authHeader(),
    })