import * as constants from "./constants";
export const initialState = {
    //profile
    isProfileLoading: false,
    profile: null,
    profileError: null,

    //edit
    isEditProfileLoading: false,
    editProfile: null,
    editProfileError: null,

    //proile
    isChangePasswordLoading: false,
    changePassword: null,
    changePasswordError: null,

    //Balance
    isBalanceLoading: false,
    balance: null,
    balanceError: null
};
const settingsReducer = (state = initialState, action)=> {
    switch (action.type) {

        //Get profile
        case constants.GET_PROFILE_REQUESTING:
            return {
                ...state,
                isProfileLoading: true,
            };

        case constants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                isProfileLoading: false,
                profile:action.payload,
                profileError:null,
            };

        case constants.GET_PROFILE_FAILED:
            return {
                ...state,
                isProfileLoading: false,
                profile:action.payload,
                profileError:action.error,
            };


        //Get balance
        case constants.GET_BALANCE_REQUESTING:
            return {
                ...state,
                isBalanceLoading: true,
            };

        case constants.GET_BALANCE_SUCCESS:
            return {
                ...state,
                isBalanceLoading: false,
                balance:action.payload,
                balanceError:null,
            };

        case constants.GET_BALANCE_FAILED:
            return {
                ...state,
                isBalanceLoading: false,
                balance:action.payload,
                balanceError:action.error,
            };

        //Edit profile
        case constants.EDIT_PROFILE_REQUESTING:
            return {
                ...state,
                isEditProfileLoading: true,
            };

        case constants.EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                isEditProfileLoading: false,
                editProfile:action.payload,
                editProfileError:null,
            };

        case constants.EDIT_PROFILE_FAILED:
            return {
                ...state,
                isEditProfileLoading: false,
                editProfile:action.payload,
                editProfileError:action.error,
            };


        //Change password
        case constants.CHANGE_PASSWORD_REQUESTING:
            return {
                ...state,
                isChangePasswordLoading: true,
            };

        case constants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isChangePasswordLoading: false,
                changePassword:action.payload,
                changePasswordError:null,
            };

        case constants.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                isChangePasswordLoading: false,
                changePassword:action.payload,
                changePasswordError:action.error,
            };

        default:
            return state;
    }
}
export default settingsReducer
