import React from 'react';
import {Helmet,HelmetProvider} from "react-helmet-async";
const LayoutPayments =({children})=>{

    return (
            <>
                {children}
            </>

    )
}

export default LayoutPayments
