import {all} from 'redux-saga/effects';
import searchSaga from "./search/saga";
import authSaga from "./auth/saga";
import documentsSaga from "./documents/saga";
import cartSaga from "./cart/saga";
import settingsSaga from "./settings/saga";
import paymentsSaga from "./payments/saga";
import apikeySaga from "./api-key/saga";
import logsSaga from "./logs/saga";

/**
 *
 */
export default function* rootSagas() {
    yield all([
        searchSaga(),
        authSaga(),
        documentsSaga(),
        settingsSaga(),
        cartSaga(),
        paymentsSaga(),
        apikeySaga(),
        logsSaga()
    ]);
}
