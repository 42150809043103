import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import * as Actions from "../../store/payments/actions";

export const CheckoutForm=(props)=>{
    //
    const useModal= useSelector(state => state?.modal);
    const paymentsState = useSelector(state => state?.payments);
    const {isPaymentLoading,isPaymentCartLoading}=paymentsState
    // @ts-ignore
    const dispatch = useDispatch();
    const nextLink=props.nextLink;

    const handleSubmit = async event => {
        event.preventDefault();

        const { stripe, elements } = props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);

        if (result.error) {
            toast.error(result?.error?.message)
        } else {

            const data ={
                source:result?.token?.id,
                payment_method_id:useModal?.data?.payment_method_id,
               // email:useModal?.data?.email,
                currency: useModal?.data?.currency,
                description:useModal?.data?.description,
            };

            dispatch(Actions.paymentsCartRequest(useModal?.data?.cart_data,data,useModal?.data?.history));
           // dispatch(paymentsRequest(data,nextLink,useModal?.data?.history));
        }
    };
    return (
        <div>
            <div className="product-info">
                <h4 className="product-price">{useModal?.data?.total_amount} FCFA</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <CardSection />
                <button disabled={isPaymentLoading || isPaymentCartLoading} className="btn-pay">{(isPaymentLoading || isPaymentCartLoading)?"Achat en cours...":"Acheter maintenant"}</button>
            </form>
        </div>
    );
}


export default function InjectedCheckoutForm(props) {
    // @ts-ignore
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm
                    nextLink={props?.nextLink}
                    stripe={stripe}
                    elements={elements}
                />
            )}
        </ElementsConsumer>
    );
}
