import * as constants from "./constants";

export const  loginRequest =(payload,route,history)=> {
    return { type: constants.LOGIN_REQUESTING, payload,route,history}
}

export const loginSuccess=(payload)=>{
    return { type:constants.LOGIN_SUCCESS, payload }
}

export const loginFailed=(error)=>{
    return { type:constants.LOGIN_FAILED, error }
}

//register
export const  registerRequest =(payload,typeRoute,history)=> {
    return { type: constants.REGISTER_REQUESTING, payload,typeRoute ,history}
}

export const registerSuccess=(payload)=>{
    return { type:constants.REGISTER_SUCCESS, payload }
}

export const registerFailed=(error)=>{
    return { type:constants.REGISTER_FAILED, error }
}

//Verification opt
export const  verificationOtpRequest =(payload,typeRoute,history)=> {
    return { type: constants.VERIFICATION_OTP_REQUESTING, payload ,typeRoute,history}
}

export const verificationOtpSuccess=(payload)=>{
    return { type:constants.VERIFICATION_OTP_SUCCESS, payload }
}

export const verificationOtpFailed=(error)=>{
    return { type:constants.VERIFICATION_OTP_FAILED, error }
}

//Forgot password
export const  forgotPasswordRequest =(payload,history)=> {
    return { type: constants.FORGOT_PASSWORD_REQUESTING, payload ,history}
}

export const forgotPasswordSuccess=(payload)=>{
    return { type:constants.FORGOT_PASSWORD_SUCCESS, payload }
}

export const forgotPasswordFailed=(error)=>{
    return { type:constants.FORGOT_PASSWORD_FAILED, error }
}

//Reset password
export const  resetPasswordRequest =(payload,resetForm,history)=> {
    return { type: constants.RESET_PASSWORD_REQUESTING, payload,resetForm ,history}
}

export const resetPasswordSuccess=(payload)=>{
    return { type:constants.RESET_PASSWORD_SUCCESS, payload }
}

export const resetPasswordFailed=(error)=>{
    return { type:constants.RESET_PASSWORD_FAILED, error }
}
