//List des documents achetés
export const PAYMENTS_LIST_REQUESTING = 'PAYMENTS_LIST_REQUESTING'
export const PAYMENTS_LIST_SUCCESS = 'PAYMENTS_LIST_SUCCESS'
export const PAYMENTS_LIST_FAILED = 'PAYMENTS_LIST_FAILED'

//Create pay achetés
export const PAYMENTS_CART_REQUESTING = 'PAYMENTS_CART_REQUESTING'
export const PAYMENTS_CART_SUCCESS = 'PAYMENTS_CART_SUCCESS'
export const PAYMENTS_CART_FAILED = 'PAYMENTS_CART_FAILED'


export const PAYMENTS_REQUESTING = 'PAYMENTS_REQUESTING'
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS'
export const PAYMENTS_FAILED = 'PAYMENTS_FAILED'


