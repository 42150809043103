import * as constants from "./constants";

//Apikey list
export const  apikeyListRequest =(payload)=> {
    return { type: constants.API_KEY_LIST_REQUESTING,payload}
}

export const  apikeyListSuccess=(payload)=>{
    return { type:constants.API_KEY_LIST_SUCCESS, payload }
}

export const  apikeyListFailed=(error)=>{
    return { type:constants.API_KEY_LIST_FAILED, error }
}

//Paginate API_KEY
export const  paginateApikeyListRequest =(url)=> {
    return { type: constants.PAGINATE_API_KEY_LIST_REQUESTING,url}
}

export const  paginateApikeyListSuccess=(payload)=>{
    return { type:constants.PAGINATE_API_KEY_LIST_SUCCESS, payload }
}

export const  paginateApikeyListFailed=(error)=>{
    return { type:constants.PAGINATE_API_KEY_LIST_FAILED, error }
}
