export const LOGIN_REQUESTING = 'auth/LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';

export const REGISTER_REQUESTING = 'auth/REGISTER_REQUESTING';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILED = 'auth/REGISTER_FAILED';

export const VERIFICATION_OTP_REQUESTING = 'auth/VERIFICATION_OTP_REQUESTING';
export const VERIFICATION_OTP_SUCCESS = 'auth/VERIFICATION_OTP_SUCCESS';
export const VERIFICATION_OTP_FAILED = 'auth/VERIFICATION_OTP_FAILED';

export const FORGOT_PASSWORD_REQUESTING = 'auth/FORGOT_PASSWORD_REQUESTING';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'auth/FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_REQUESTING = 'auth/RESET_PASSWORD_REQUESTING';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'auth/RESET_PASSWORD_FAILED';