import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";

function* loadCartSaga(profilePayload) {
    const {id}=profilePayload
    try {
        const response = yield call(_service.getCart, id);
        if (response.status === 200) {
            yield put(Actions.getCartSuccess(response?.data));
        } else {
            yield put(Actions.getCartFailed(response.error));
            yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
        }
    } catch (e) {
        yield put(Actions.getCartFailed(e));
        yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    }
}

export default function* cartSaga() {
    yield takeEvery(Constants.GET_CART_REQUESTING, loadCartSaga,);
}
