import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";
import * as ActionsModal from "../modal/actions";
import * as navigations from "../../utils/navigation";
import {searchResetCart} from "../cart/actions";

function* loadPaymentsListSaga() {
  
  try {

    const response = yield call(_service.fetchPayments);
    if (response.status === 200) {
      yield put(Actions.paymentsListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paymentsListFailed(response));
    }

  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paymentsListFailed(e));
  }
}

function* loadPaymentsCartSaga(paymentsCartPayload) {
  const {payload,payment_method,history}=paymentsCartPayload

 try {

    const response = yield call(_service.createPaymentCart,payload);

    if (response.status === 200 ||  response.status === 201) {
      yield put(Actions.paymentsCartSuccess(response?.data));
      yield put(Actions.paymentsRequest(payment_method,history));

/*        if(type_payment_method==='carte bancaire'){
          yield put(ActionsModal.showStripePaymentModal({
             nextLink:nextLink,
             history:history,
             email:user_data?.email,
             total_amount:total_amount,
             payment_method_id:payment_method?.payment_method_id,
             description:'Il s\'agit d\'un paiement avec la carte bancaire',
             amount:total_amount,
             currency: 'XOF',
          }));

        }
        if(type_payment_method==='mobile money'){

         const data ={
             payment_method_id:payment_method?.payment_method_id,
             description:'Il s\'agit d\'un paiement avec Cinetpay',
             currency: 'XOF',
         }

         yield call(payByCinetPay,
             200,
             //total_amount,
             payload?.phone,
             user_data?.last_name,
             user_data?.first_name,
             user_data?.email,
             "CI",
             "CI",
             nextLink,
             history,data,dispatch);
        }*/

    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paymentsCartFailed(response));
    }

  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paymentsCartFailed(e));
  }
}

function* loadPaymentsSaga(paymentsPayload) {
    const {payload,history} = paymentsPayload;

    try {
        const response = yield call(_service.createPayment, payload);

        if (response.status === 200 ||  response.status === 201) {

            yield put(Actions.paymentsSuccess(response?.data));
           // yield call(redirectTo,nextLink,history);;
            yield call(history,navigations.DASHBOARD);
            yield put(searchResetCart({}))
            yield put(ActionsModal.hideModal());

            setTimeout(()=>{
                window.location.reload();
            },5000)
        } else {
            yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
            yield put(Actions.paymentsFailed(response));
            setTimeout(()=>{
                window.location.reload();
            },5000)
        }

    } catch (error) {
        yield put(Actions.paymentsFailed(error));
        toast.error(error);
        setTimeout(()=>{
            window.location.reload();
        },5000)
    }

}

export default function* paymentsSaga() {
  yield takeEvery(Constants.PAYMENTS_LIST_REQUESTING, loadPaymentsListSaga);
  yield takeEvery(Constants.PAYMENTS_CART_REQUESTING, loadPaymentsCartSaga);
  yield takeEvery(Constants.PAYMENTS_REQUESTING, loadPaymentsSaga);

}
