import * as constants from "./constants";
import {toast} from "react-toastify";
export const initialState = {
    //search for chart
    cart:[],
    isLoadingCart:false,
    isOnlineCartLoading:false,
    onlineCart:[],
    onlineCartError:null
}
const cartReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.SEARCH_ADD_TO_CART:
            const itemInCart = state?.cart.find((item) => item.file_id === action.payload.file_id);
            const newCart=(itemInCart===undefined)?[action.payload,...state?.cart]:[...state?.cart];

            if(itemInCart===undefined){
                toast.success("Le document a été ajouté au panier.");
            }else{
                toast.error("Le document a déjà été ajouté au panier.");
            }
            return {...state, cart:newCart}
        case constants.SEARCH_REMOVE_FROM_CART:
            const newCartRemovable = state?.cart.filter((item) => item.file_id !== action?.payload?.file_id);
            return {
                ...state,
                cart:newCartRemovable,
            };

        case constants.SEARCH_RESET_CART:
            return {
                ...state,
                cart:[],
            };

        // Get Cart
        case constants.GET_CART_REQUESTING:
            return {
                ...state,
                isOnlineCartLoading: true,
            };

        case constants.GET_CART_SUCCESS:
            return {
                ...state,
                isOnlineCartLoading: false,
                onlineCart:action.payload,
                onlineCartError:null,
            };

        case constants.GET_CART_FAILED:
            return {
                ...state,
                isOnlineCartLoading: false,
                onlineCart:action.payload,
                onlineCartError:action.error,
            };

        default:
            return state;
    }
}

export default cartReducer
