import * as constants from "./constants";
//For search
export const  searchRequest =(payload)=> {
    return { type: constants.SEARCH_REQUESTING,payload}
}

export const searchSuccess=(payload)=>{
    return { type:constants.SEARCH_SUCCESS, payload }
}

export const searchFailed=(error)=>{
    return { type:constants.SEARCH_FAILED, error }
}

//For search details
export const  searchDetailRequest =(payload)=> {
    return { type: constants.SEARCH_DETAIL_REQUESTING,payload}
}

export const  searchDetailSuccess=(payload)=>{
    return { type:constants.SEARCH_DETAIL_SUCCESS, payload }
}

export const  searchDetailFailed=(error)=>{
    return { type:constants.SEARCH_DETAIL_FAILED, error }
}

//Decision pour le demandeur
export const  searchDecisionApplicantsRequest =(payload)=> {
    return { type: constants.SEARCH_DECISION_APPLICANTS_REQUESTING,payload}
}

export const  searchDecisionApplicantsSuccess=(payload)=>{
    return { type:constants.SEARCH_DECISION_APPLICANTS_SUCCESS, payload }
}

export const  searchDecisionApplicantsFailed=(error)=>{
    return { type:constants.SEARCH_DECISION_APPLICANTS_FAILED, error }
}

export const  paginateSearchDecisionApplicantsRequest =(url,denomination)=> {
    return { type: constants.PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING,url,denomination}
}

export const  paginateSearchDecisionApplicantsSuccess=(payload)=>{
    return { type:constants.PAGINATE_SEARCH_DECISION_APPLICANTS_SUCCESS, payload }
}

export const  paginateSearchDecisionApplicantsFailed=(error)=>{
    return { type:constants.PAGINATE_SEARCH_DECISION_APPLICANTS_FAILED, error }
}

//Decision pour le defendeur
export const  searchDecisionDefendantsRequest =(payload)=> {
    return { type: constants.SEARCH_DECISION_DEFENDANTS_REQUESTING,payload}
}

export const  searchDecisionDefendantsSuccess=(payload)=>{
    return { type:constants.SEARCH_DECISION_DEFENDANTS_SUCCESS, payload }
}

export const  searchDecisionDefendantsFailed=(error)=>{
    return { type:constants.SEARCH_DECISION_DEFENDANTS_FAILED, error }
}

export const  paginateSearchDecisionDefendantsRequest =(url,denomination)=> {
    return { type: constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_REQUESTING,url,denomination}
}

export const  paginateSearchDecisionDefendantsSuccess=(payload)=>{
    return { type:constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_SUCCESS, payload }
}

export const  paginateSearchDecisionDefendantsFailed=(error)=>{
    return { type:constants.PAGINATE_SEARCH_DECISION_DEFENDANTS_FAILED, error }
}

//Autre decision pour le demandeur
export const  searchOthersApplicantsRequest =(payload)=> {
    return { type: constants.SEARCH_OTHERS_APPLICANTS_REQUESTING,payload}
}

export const  searchOthersApplicantsSuccess=(payload)=>{
    return { type:constants.SEARCH_OTHERS_APPLICANTS_SUCCESS, payload }
}

export const  searchOthersApplicantsFailed=(error)=>{
    return { type:constants.SEARCH_OTHERS_APPLICANTS_FAILED, error }
}
export const  paginateSearchOthersApplicantsRequest =(url,denomination)=> {
    return { type: constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_REQUESTING,url,denomination}
}

export const  paginateSearchOthersApplicantsSuccess=(payload)=>{
    return { type:constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_SUCCESS, payload }
}

export const  paginateSearchOthersApplicantsFailed=(error)=>{
    return { type:constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_FAILED, error }
}

//Autre decision pour le defendeur
export const  searchOthersDefendantsRequest =(payload)=> {
    return { type: constants.SEARCH_OTHERS_DEFENDANTS_REQUESTING,payload}
}

export const  searchOthersDefendantsSuccess=(payload)=>{
    return { type:constants.SEARCH_OTHERS_DEFENDANTS_SUCCESS, payload }
}

export const  searchOthersDefendantsFailed=(error)=>{
    return { type:constants.SEARCH_OTHERS_DEFENDANTS_FAILED, error }
}

export const  paginateSearchOthersDefendantsRequest =(url,denomination)=> {
    return { type: constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_REQUESTING,url,denomination}
}

export const  paginateSearchOthersDefendantsSuccess=(payload)=>{
    return { type:constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_SUCCESS, payload }
}

export const  paginateSearchOthersDefendantsFailed=(error)=>{
    return { type:constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_FAILED, error }
}

export const  searchDirigeantsDetailRequest =(payload)=> {
    return { type: constants.SEARCH_DETAIL_DIRIGEANTS_REQUESTING,payload}
}

export const  searchDirigeantsDetailSuccess=(payload)=>{
    return { type:constants.SEARCH_DETAIL_DIRIGEANTS_SUCCESS, payload }
}

export const  searchDirigeantsDetailFailed=(error)=>{
    return { type:constants.SEARCH_DETAIL_DIRIGEANTS_FAILED, error }
}

//ApiKey cart
export const  searchAddToCart=(payload)=>{
    return { type:constants.SEARCH_ADD_TO_CART, payload }
}

export const  searchRemoveFromCart=(payload)=>{
    return { type:constants.SEARCH_REMOVE_FROM_CART, payload }
}