import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const login =  async(payload) =>
    await axios.post(`${API_URL}/recouv/api-token-auth`,payload ,{
        headers: authHeader(),
    })

export const register =  async(payload) =>
    await axios.post(`${API_URL}/recouv/users/`,payload ,{
        headers: authHeader(),
    })

export const verificationOtp =  async(payload) =>
    await axios.post(`${API_URL}/recouv/activate-account`,payload ,{
        headers: authHeader(),
    })

export const forgotPassword =  async(payload) =>
    await axios.post(`${API_URL}/recouv/password-reset`,payload ,{
        headers: authHeader(),
    })

export const resetPassword =  async(payload) =>
    await axios.post(`${API_URL}/recouv/password-reset-confirm`,payload ,{
        headers: authHeader(),
    })