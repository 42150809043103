import React from "react";
import {Elements } from "@stripe/react-stripe-js";
import {Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import CheckoutForm from "./CheckoutForm";
import {useDispatch, useSelector} from "react-redux";
import { hideModal } from "../../store/modal/actions";
import {loadStripe} from "@stripe/stripe-js";
const STRIPE_PK_LIVE= process.env.REACT_APP_STRIPE_PK_LIVE;

const stripePromise = loadStripe(`${STRIPE_PK_LIVE}`);
function StripeModal() {

    // @ts-ignore
    const useModal= useSelector(state => state?.modal);
    const dispatch = useDispatch();
    // @ts-ignore
    return (
        <Modal
            onClose={() =>dispatch(hideModal())}
            closeable
            isOpen={useModal.isOpenStripePayment}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
        >
            <ModalHeader>
                <div className="tranch-confirm-header">
                    <div className="tranch-confirm-header-box-title">
                        <h5 className="tranch-confirm-header-title">
                            Achat de document
                        </h5>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        pays={useModal?.data?.pays}
                        nextLink={useModal?.data?.nextLink}
                    />
                </Elements>
            </ModalBody>
        </Modal>
    );
}
export default StripeModal;
