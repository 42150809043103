import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";

function* loadDocumentsListSaga(documentsPayload) {
  const {payload}=documentsPayload

let url=`recouv/datadocumentuser/?user__id=${payload.user_id}`
//  let url=`recouv/datadocumentuser/`

/*  if(payload?.search!==''){
    url+=`&search=${payload?.search}`
  }*/

  try {
    const response = yield call(_service.fetchDocument,url);
    if (response.status === 200) {
      yield put(Actions.documentsListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.documentsListFailed(response));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.documentsListFailed(e));
  }
}

function* loadPaginateDocumentsListSaga(payload) {
  const {url}=payload
  try {

   const response = yield call(_service.paginateFetchDocument,url);
    if (response.status === 200) {
      yield put(Actions.paginateDocumentsListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateDocumentsListFailed(response.error));
    }
  } catch (e) {
    yield put(Actions.paginateDocumentsListFailed(e));
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
  }
}

export default function* documentsSaga() {
  yield takeEvery(Constants.DOCUMENTS_LIST_REQUESTING, loadDocumentsListSaga);
  yield takeEvery(Constants.PAGINATE_DOCUMENTS_LIST_REQUESTING, loadPaginateDocumentsListSaga);
}
