//Change password
export const CHANGE_PASSWORD_REQUESTING = 'CHANGE_PASSWORD_REQUESTING'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

//Edit profile
export const EDIT_PROFILE_REQUESTING = 'EDIT_PROFILE_REQUESTING'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED'

//Get profile
export const GET_PROFILE_REQUESTING = 'GET_PROFILE_REQUESTING'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED'

//Get solde
export const GET_BALANCE_REQUESTING = 'GET_BALANCE_REQUESTING'
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS'
export const GET_BALANCE_FAILED = 'GET_BALANCE_FAILED'
