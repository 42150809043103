import * as constants from "./constants";

export const initialState = {
    isLoading: false,
    errors: null,
    isAuthenticated: false,
    user: null,
    access_token:null,
    refresh_token:null,
    //Register OTP
    isRegisterLoading:false,
    register:null,
    registerError:null,
    //Verification OTP
    isVerificationOtpLoading:false,
    verificationOtp:null,
    verificationOtpError:null,
    //Forgot password
    isForgotPasswordLoading:false,
    forgotPassword:null,
    forgotPasswordError:null,
    //Reset password
    isResetPasswordLoading:false,
    resetPassword:null,
    resetPasswordError:null,
}

const authReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.LOGIN_REQUESTING:
            return {
                ...state,
                isLoading: true,
            };
        case constants.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload?.user,
                access_token: action.payload?.access_token,
                refresh_token: action.payload?.refresh_token,
                errors: null
            };

        case constants.LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errors:action.error,
                user: action.payload?.user,
                access_token: null,
                refresh_token: null,
            };

        //Inscription d'OTP
        case constants.REGISTER_REQUESTING:
            return {
                ...state,
                isRegisterLoading: true,
            };

        case constants.REGISTER_SUCCESS:
            return {
                ...state,
                isRegisterLoading: false,
                register:action.payload,
                registerError:null,
            };

        case constants.REGISTER_FAILED:
            return {
                ...state,
                isRegisterLoading: false,
                register:null,
                registerError:action.error,
            };

        //Vérification d'OTP
        case constants.VERIFICATION_OTP_REQUESTING:
            return {
                ...state,
                isVerificationOtpLoading: true,
            };
        case constants.VERIFICATION_OTP_SUCCESS:
            return {
                ...state,
                isVerificationOtpLoading: false,
                verificationOtp:action.payload,
                verificationOtpError:null,
            };
        case constants.VERIFICATION_OTP_FAILED:
            return {
                ...state,
                isVerificationOtpLoading: false,
                verificationOtp:null,
                verificationOtpError:action.error
            };

        //Forgot password
        case constants.FORGOT_PASSWORD_REQUESTING:
            return {
                ...state,
                isForgotPasswordLoading: true,
            };
        case constants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isForgotPasswordLoading: false,
                forgotPassword:action.payload,
                forgotPasswordError:null,
            };
        case constants.FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                isForgotPasswordLoading: false,
                forgotPassword:null,
                forgotPasswordError:action.error
            };

        //Reset password
        case constants.RESET_PASSWORD_REQUESTING:
            return {
                ...state,
                isResetPasswordLoading: true,
            };
        case constants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isResetPasswordLoading: false,
                resetPassword:action.payload,
                resetPasswordError:null,
            };
        case constants.RESET_PASSWORD_FAILED:
            return {
                ...state,
                isResetPasswordLoading: false,
                resetPassword:null,
                resetPasswordError:action.error
            };

        default:
            return state;
    }
}
export default authReducer
