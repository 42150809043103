import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";

function* loadLogsListSaga() {
  try {
    const response = yield call(_service.fetchLogs);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.logsListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.logsListFailed(response));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.logsListFailed(e));
  }

}

function* loadPaginateLogsListSaga(payload) {
  const {url}=payload
  try {

   const response = yield call(_service.paginateFetchLogs,url);
    if (response.status === 200) {
      yield put(Actions.paginateLogsListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateLogsListFailed(response.error));
    }
  } catch (e) {
    yield put(Actions.paginateLogsListFailed(e));
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
  }
}

export default function* logsSaga() {
  yield takeEvery(Constants.LOGS_LIST_REQUESTING, loadLogsListSaga);
  yield takeEvery(Constants.PAGINATE_LOGS_LIST_REQUESTING, loadPaginateLogsListSaga);
}
