import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const fetchLogs =async () =>
    await axios.get(`${API_URL}/recouv/users_history/` ,{
        headers: authHeader(),
    })

export const paginateFetchLogs =async (url) =>
    await axios.get(url ,{
        headers: authHeader(),
    })