import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const fetchApikey =async (url) =>
    await axios.get(`${API_URL}/${url}` ,{
        headers: authHeader(),
    })

export const paginateFetchApikey =async (url) =>
    await axios.get(url ,{
        headers: authHeader(),
    })