import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";

function* loadProfileSaga(profilePayload) {
  const {id,setPhone}=profilePayload
  try {
    const response = yield call(_service.getUser, id);
    if (response.status === 200) {
      yield put(Actions.getProfileSuccess(response?.data));
      setPhone(response?.data?.phone);
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.getProfileFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.getProfileFailed(e));
  }
}

function* loadBalanceSaga(balancePayload) {
  const {id}=balancePayload
  try {
    const response = yield call(_service.getBalance, id);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.getBalanceSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.getBalanceFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.getBalanceFailed(e));
  }
}


function* loadEditProfileSaga(profilePayload) {
  const {id,payload}=profilePayload
  try {
    const response = yield call(_service.editUser,id, payload);
    if (response.status === 200 || response.status === 201) {

      yield put(Actions.editProfileSuccess(response?.data?.data));
      yield call(toast.success,"Le profil a été édité avec succès.");

    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.editProfileFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.editProfileFailed(e));
  }
}

function* loadChangePasswordSaga(passwordPayload) {
  const {id,resetForm,payload}=passwordPayload
  try {
    const response = yield call(_service.editUser,id, payload);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.changePasswordSuccess(response?.data?.data));
      resetForm();
      yield call(toast.success,"Le mot de passe a été modifié avec succès.");
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.changePasswordFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.changePasswordFailed(e));
  }
}

export default function* settingsSaga() {
  yield takeEvery(Constants.GET_PROFILE_REQUESTING, loadProfileSaga,);
  yield takeEvery(Constants.EDIT_PROFILE_REQUESTING, loadEditProfileSaga,);
  yield takeEvery(Constants.CHANGE_PASSWORD_REQUESTING, loadChangePasswordSaga,);
  yield takeEvery(Constants.GET_BALANCE_REQUESTING, loadBalanceSaga);
}
