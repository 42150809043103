import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";
function* loadSearchSaga(searchPayload) {
  const {payload}=searchPayload
  try {
    const response = yield call(_service.fetchSearch, payload);
    if (response.status === 200) {
      yield put(Actions.searchSuccess(response?.data?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchFailed(e));
  }
}

function* loadSearchDetailSaga(searchPayload) {
  const {payload}=searchPayload
  try {
    const response = yield call(_service.fetchSearchDetail, payload);
    if (response.status === 200) {
      yield put(Actions.searchDetailSuccess(response?.data?.data[0]));

      const list_dirigeants={
        dirigeants:response?.data?.data[0].dirigeants
      }

      yield put(Actions.searchDirigeantsDetailRequest(list_dirigeants));

    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchDetailFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchDetailFailed(e));
  }
}

//Saga pour les decisions du demandeur
function* loadSearchDecisionApplicantsSaga(decisionApplicantsPayload) {
  const {payload}=decisionApplicantsPayload
  try {
    const response = yield call(_service.fetchSearchDecision,'recouv/decision-demandeurs', payload);
    if (response.status === 200) {
      yield put(Actions.searchDecisionApplicantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchDecisionApplicantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchDecisionApplicantsFailed(e));
  }
}

function* loadPaginateSearchDecisionApplicantsSaga(payload) {
  const {url,denomination}=payload
  try {
    const response = yield call(_service.paginateFetchSearchDecision,url, denomination);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.paginateSearchDecisionApplicantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateSearchDecisionApplicantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paginateSearchDecisionApplicantsFailed(e));
  }
}



//Saga pour les decisions du defendeur
function* loadSearchDecisionDefendantsSaga(decisionDefendantsPayload) {
  const {payload}=decisionDefendantsPayload
  try {
    const response = yield call(_service.fetchSearchDecision,'recouv/decision-defendeurs', payload);
    if (response.status === 200) {
      yield put(Actions.searchDecisionDefendantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchDecisionDefendantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchDecisionDefendantsFailed(e));
  }
}

function* loadPaginateSearchDecisionDefendantsSaga(payload) {
  const {url,denomination}=payload
  try {
    const response = yield call(_service.paginateFetchSearchDecision,url, denomination);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.paginateSearchDecisionDefendantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateSearchDecisionDefendantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paginateSearchDecisionDefendantsFailed(e));
  }
}

//Saga pour les autres decisions du demandeur
function* loadSearchOthersApplicantsSaga(othersApplicantsPayload) {
  const {payload}=othersApplicantsPayload
  try {
    const response = yield call(_service.fetchSearchDecision,'recouv/others-demandeurs', payload);
    if (response.status === 200) {

      yield put(Actions.searchOthersApplicantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchOthersApplicantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchOthersApplicantsFailed(e));
  }
}

function* loadPaginateSearchOthersApplicantsSaga(payload) {
  const {url,denomination}=payload
  try {
    const response = yield call(_service.paginateFetchSearchDecision,url, denomination);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.paginateSearchOthersApplicantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateSearchOthersApplicantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paginateSearchOthersApplicantsFailed(e));
  }
}

//Saga pour les autres decisions du defendeur
function* loadSearchOthersDefendantsSaga(OthersDefendantsPayload) {
  const {payload}=OthersDefendantsPayload
  try {
    const response = yield call(_service.fetchSearchDecision,'recouv/others-defendeurs', payload);
    if (response.status === 200) {
      yield put(Actions.searchOthersDefendantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchOthersDefendantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchOthersDefendantsFailed(e));
  }
}

function* loadPaginateSearchOthersDefendantsSaga(payload) {
  const {url,denomination}=payload
  try {
    const response = yield call(_service.paginateFetchSearchDecision,url, denomination);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.paginateSearchOthersDefendantsSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateSearchOthersDefendantsFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.paginateSearchOthersDefendantsFailed(e));
  }
}

function* loadSearchDirigeantsDetailSaga(searchPayload) {
  const {payload}=searchPayload
  try {
    const response = yield call(_service.fetchSearchDirigeantsDetail, payload);
    if (response.status === 200) {
      yield put(Actions.searchDirigeantsDetailSuccess(response?.data?.dirigeants));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.searchDirigeantsDetailFailed(response.error));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.searchDirigeantsDetailFailed(e));
  }
}

export default function* searchSaga() {
  yield takeEvery(
    Constants.SEARCH_REQUESTING,
      loadSearchSaga,
  );
  yield takeEvery(
      Constants.SEARCH_DETAIL_REQUESTING,
      loadSearchDetailSaga,
  );

  yield takeEvery(
      Constants.SEARCH_DECISION_APPLICANTS_REQUESTING,
      loadSearchDecisionApplicantsSaga,
  );
  yield takeEvery(
      Constants.PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING,
      loadPaginateSearchDecisionApplicantsSaga,
  );

  yield takeEvery(
      Constants.SEARCH_DECISION_APPLICANTS_REQUESTING,
      loadSearchDecisionDefendantsSaga,
  );
  yield takeEvery(
      Constants.PAGINATE_SEARCH_DECISION_APPLICANTS_REQUESTING,
      loadPaginateSearchDecisionDefendantsSaga,
  );

  yield takeEvery(
      Constants.SEARCH_OTHERS_APPLICANTS_REQUESTING,
      loadSearchOthersApplicantsSaga,
  );
  yield takeEvery(
      Constants.PAGINATE_SEARCH_OTHERS_APPLICANTS_REQUESTING,
      loadPaginateSearchOthersApplicantsSaga,
  );

  yield takeEvery(
      Constants.SEARCH_OTHERS_DEFENDANTS_REQUESTING,
      loadSearchOthersDefendantsSaga,
  );
  yield takeEvery(
      Constants.PAGINATE_SEARCH_OTHERS_DEFENDANTS_REQUESTING,
      loadPaginateSearchOthersDefendantsSaga,
  );

  yield takeEvery(
      Constants.SEARCH_DETAIL_DIRIGEANTS_REQUESTING,
      loadSearchDirigeantsDetailSaga,
  );
}
