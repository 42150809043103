import React from "react";
import {NavbarWrapper} from "./navbar.style";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getLocalState} from "../../utils/localStorage";
import {DASHBOARD, LOGIN} from "../../utils/navigation";

const Navbar = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    const currentUser = getLocalState("_currentUser");

    const login=()=>{

    }

    return (
           <NavbarWrapper>
               <nav className="navbar navbar-expand-lg fixed-top">
                   <div className="container-fluid">
                       <Link to="/"  className="navbar-brand">
                           <img className="lg-recoov-logo" src="/assets/images/logo-recoov.png" loading="lazy"  alt="logo de Datagreffe"/>
                       </Link>
                       <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                           <span className="navbar-toggler-icon"></span>
                       </button>
                       <div className="collapse navbar-collapse" id="navbarSupportedContent">
                           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {/*             <li className="nav-item">
                                   <a className="nav-link " aria-current="page"  href="#">Annuaire des entreprises</a>
                               </li>*/}

                           </ul>
                           <div className="d-flex lg-recoov-nav-btn-box">
                               <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                   <li className="nav-item dropdown">
                                       <Link className="nav-link dropdown-toggle" to="#" id="navbarDarkDropdownMenuLinkCA2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                           API<span className="caret"></span>
                                       </Link>
                                       <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLinkCA">
                                           <li> <Link className="dropdown-item" to="#">API Documentation</Link> </li>
                                           <li> <Link className="dropdown-item" to="#">Developer</Link> </li>
                                       </ul>
                                   </li>

                                   <li className="nav-item">
                                       <Link className="nav-link" to="#tarif">Blog</Link>
                                   </li>

                                   <li className="nav-item">
                                       <Link className="nav-link" to="#tarif">
                                           <img className="nav-link-image" src="/assets/images/flag-civ.png" loading="lazy"  alt="Flag CIV"/> CI
                                       </Link>
                                   </li>

                                   {(currentUser)?(<li className="nav-item"><Link className="nav-link search-cusor" to={DASHBOARD}>Accès client</Link></li>):(<li className="nav-item"><Link className="nav-link search-cusor" to={LOGIN}>Connexion</Link></li>)}

                               </ul>
                           </div>
                       </div>
                   </div>
               </nav>
           </NavbarWrapper>
       );
};

export default Navbar;
