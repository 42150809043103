import {put, call, takeEvery} from 'redux-saga/effects';
import * as Actions from './actions';
import * as _service from './service';
import * as Constants from './constants';
import {toast} from "react-toastify";

function* loadApikeyListSaga(ApikeyPayload) {
  const {payload}=ApikeyPayload

  let url=`recouv/get-apikey/${payload.company_id}`

  try {
    const response = yield call(_service.fetchApikey,url);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.apikeyListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.apikeyListFailed(response));
    }
  } catch (e) {
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
    yield put(Actions.apikeyListFailed(e));
  }
}

function* loadPaginateApikeyListSaga(payload) {
  const {url}=payload
  try {

   const response = yield call(_service.paginateFetchApikey,url);
    if (response.status === 200 || response.status === 201) {
      yield put(Actions.paginateApikeyListSuccess(response?.data));
    } else {
      yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
      yield put(Actions.paginateApikeyListFailed(response.error));
    }
  } catch (e) {
    yield put(Actions.paginateApikeyListFailed(e));
    yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
  }
}

export default function* apikeySaga() {
  yield takeEvery(Constants.API_KEY_LIST_REQUESTING, loadApikeyListSaga);
  yield takeEvery(Constants.PAGINATE_API_KEY_LIST_REQUESTING, loadPaginateApikeyListSaga);
}
