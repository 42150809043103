import * as constants from "./constants";

//Change password
export const  changePasswordRequest =(id,resetForm,payload)=> {
    return { type: constants.CHANGE_PASSWORD_REQUESTING,id,resetForm,payload}
}

export const  changePasswordSuccess=(payload)=>{
    return { type:constants.CHANGE_PASSWORD_SUCCESS, payload }
}

export const  changePasswordFailed=(error)=>{
    return { type:constants.CHANGE_PASSWORD_FAILED, error }
}

//Edit profile
export const  editProfileRequest =(id,payload)=> {
    return { type: constants.EDIT_PROFILE_REQUESTING,id,payload}
}

export const  editProfileSuccess=(payload)=>{
    return { type:constants.EDIT_PROFILE_SUCCESS, payload }
}

export const  editProfileFailed=(error)=>{
    return { type:constants.EDIT_PROFILE_FAILED, error }
}

//Get profile
export const  getProfileRequest =(id,setPhone)=> {
    return { type: constants.GET_PROFILE_REQUESTING,id,setPhone}
}

export const  getProfileSuccess=(payload)=>{
    return { type:constants.GET_PROFILE_SUCCESS, payload }
}

export const  getProfileFailed=(error)=>{
    return { type:constants.GET_PROFILE_FAILED, error }
}


//Get blalance
export const  getBalanceRequest =(id)=> {
    return { type: constants.GET_BALANCE_REQUESTING,id}
}

export const  getBalanceSuccess=(payload)=>{
    return { type:constants.GET_BALANCE_SUCCESS, payload }
}

export const  getBalanceFailed=(error)=>{
    return { type:constants.GET_BALANCE_FAILED, error }
}