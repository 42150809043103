import * as constants from "./constants";
export const initialState = {
    //Apikey
    isLoading: false,
    data: [],
    error: null
};
const ApikeyReducer = (state = initialState, action)=> {
    switch (action.type) {
        case constants.API_KEY_LIST_REQUESTING:
        case constants.PAGINATE_API_KEY_LIST_REQUESTING:
            return {
                ...state,
                isLoading:true
            };

        case constants.API_KEY_LIST_SUCCESS:
        case constants.PAGINATE_API_KEY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action?.payload,
                error: null,
            };

        case constants.API_KEY_LIST_FAILED:
        case constants.PAGINATE_API_KEY_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.error,
            };


        default:
            return state;
    }
}
export default ApikeyReducer
