import {getLocalState} from "../../utils/localStorage";

export default function authHeader() {
    const token = getLocalState('access_token');
   if (token) {
        return {
            "Content-Type": "application/json",
            'Authorization': token ? `Bearer ${token}` : ""};
    } else {
        return {
            "Content-Type": "application/json"
        };
    }
}
