import * as constants from "./constants";

//ApiKey cart
export const  searchAddToCart=(payload)=>{
    return { type:constants.SEARCH_ADD_TO_CART, payload }
}

export const  searchResetCart=(payload)=>{
    return { type:constants.SEARCH_RESET_CART,payload }
}
export const  searchRemoveFromCart=(payload)=>{
    return { type:constants.SEARCH_REMOVE_FROM_CART, payload }
}

//Cart
export const  getCartRequest =(id)=> {
    return { type: constants.GET_CART_REQUESTING,id}
}

export const  getCartSuccess=(payload)=>{
    return { type:constants.GET_CART_SUCCESS, payload }
}

export const  getCartFailed=(error)=>{
    return { type:constants.GET_CART_FAILED, error }
}