import {createStore,applyMiddleware,compose} from 'redux';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSagas from "./sagas";
import {globalReducers} from "./reducers";
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from "redux-persist";
import immutableTransform from 'redux-persist-transform-immutable';

const configureStore = () => {

    const persistConfig = {
        key: 'root',
        transforms: [immutableTransform()],
        storage: storage,
        whitelist: ['cart'],
    };

    const pReducer = persistReducer(persistConfig, globalReducers);


    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware,logger));
    const store = createStore(pReducer, enhancer);
    const persistor = persistStore(store);

    // then run the saga
    sagaMiddleware.run(rootSagas);
    return {store,persistor};
};
const {store,persistor} = configureStore(); // exported this instead;

export {store,persistor};