import * as constants from "./constants";

//Logout list
export const  paymentsListRequest =(payload)=> {
    return { type: constants.PAYMENTS_LIST_REQUESTING,payload}
}

export const  paymentsListSuccess=(payload)=>{
    return { type:constants.PAYMENTS_LIST_SUCCESS, payload }
}

export const  paymentsListFailed=(error)=>{
    return { type:constants.PAYMENTS_LIST_FAILED, error }
}

//Cart
export const  paymentsCartRequest =(payload,payment_method,history)=> {
    return { type: constants.PAYMENTS_CART_REQUESTING,payload,payment_method,history}
}

export const  paymentsCartSuccess=(payload)=>{
    return { type:constants.PAYMENTS_CART_SUCCESS, payload }
}

export const  paymentsCartFailed=(error)=>{
    return { type:constants.PAYMENTS_CART_FAILED, error }
}

//Payment
export const  paymentsRequest =(payload,history)=> {
    return { type: constants.PAYMENTS_REQUESTING,payload,history}
}

export const  paymentsSuccess=(payload)=>{
    return { type:constants.PAYMENTS_SUCCESS, payload }
}

export const  paymentsFailed=(error)=>{
    return { type:constants.PAYMENTS_FAILED, error }
}