import React, { useEffect } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as navigations from "../utils/navigation";
import { getLocalState } from "../utils/localStorage";
import { LOGIN } from "../utils/navigation";
import { Dom } from "../utils/Dom";
import {LayoutAdminWrapper} from "./LayoutAdmin.style";
import * as Icons from "react-feather";
import {Helmet, HelmetProvider} from "react-helmet-async";
const LayoutAdmin = ({ children }) => {
  let location = useLocation();
  const currentUser = getLocalState("_currentUser");
  const access_token = getLocalState("access_token");
  let navigate = useNavigate();

  const getNavLinkClass = (path, isSidemenu) => {
    return location.pathname === path
      ? `${isSidemenu ? "side-menu--active" : "menu--active"}`
      : path.split("/")[1] === location.pathname.split("/")[1]
      ? `${isSidemenu ? "side-menu--active" : "menu--active"}`
      : "";
  };

  useEffect(() => {
    if (
      (currentUser === undefined || currentUser === "") &&
      (access_token === undefined || access_token === "")
    ) {
      navigate(LOGIN);
    }
  }, []);

  return (

      <HelmetProvider>
        <Helmet>
          <script src="/assets/dist/js/app.js"></script>
        </Helmet>
        <LayoutAdminWrapper>

          <div className="mobile-menu md:hidden">
            <div className="mobile-menu-bar">
              <Link to={navigations.DASHBOARD} className="flex mr-auto search-cusor">
                <img
                    alt="Midone Tailwind HTML Admin Template"
                    className="w-16"
                    src="/assets/images/logo-recoov-white.png"
                />
              </Link>
              <span onClick={() => Dom.toggleElement("#mobile-menu-dropdown")} id="mobile-menu-toggler search-cusor">
                <Icons.BarChart2  className="w-8 h-8 text-white transform -rotate-90"/>
              </span>
            </div>
            <ul className="border-t border-theme-24 py-5 hidden" id="mobile-menu-dropdown">
              <li onClick={() => Dom.toggleElement("#mobile-menu-dropdown")}>
                <Link to={navigations.DASHBOARD}
                      className={`menu search-cusor ${getNavLinkClass(navigations.DASHBOARD, false)}`}>
                  <div className="menu__icon">
                    <Icons.Home/>
                  </div>
                  <div className="menu__title">Mes documents</div>
                </Link>
              </li>

              <li onClick={() => Dom.toggleElement("#mobile-menu-dropdown")}>
                <Link to={navigations.SEARCH}
                      className={`menu search-cusor ${getNavLinkClass(
                          navigations.SEARCH,
                          false
                      )}`}
                >
                  <div className="menu__icon">
                    <Icons.Search/>
                  </div>
                  <div className="menu__title">Faire une recherche</div>
                </Link>
              </li>

              <li onClick={() => Dom.toggleElement("#mobile-menu-dropdown")}>
                <Link to={navigations.SETTINGS}
                      className={`menu search-cusor ${getNavLinkClass(navigations.SETTINGS, false)}`}>
                  <div className="menu__icon">
                    <Icons.Settings/>
                  </div>
                  <div className="menu__title">Paramètres</div>
                </Link>
              </li>

              <li onClick={() => Dom.toggleElement("#mobile-menu-dropdown")}>
                <Link to={navigations.API_KEY} className={`menu search-cusor ${getNavLinkClass(navigations.API_KEY, false)}`}>
                  <div className="menu__icon">
                    <Icons.Key/>
                  </div>
                  <div className="menu__title">Clés API</div>
                </Link>
              </li>

              <li onClick={() => Dom.toggleElement("#mobile-menu-dropdown")}>
                <Link to={navigations.LOG} className={`menu search-cusor ${getNavLinkClass(navigations.LOG, false)}`}>
                  <div className="menu__icon">
                    <Icons.PieChart/>
                  </div>
                  <div className="menu__title">Journal</div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex">
            <nav className="side-nav">
              <Link to={navigations.DASHBOARD} className="intro-x flex items-center pl-5 pt-4 search-cusor">
                <img
                    alt="Midone Tailwind HTML Admin Template"
                    className="w-40"
                    src="/assets/images/logo-recoov-white.png"
                />
              </Link>
              <div className="side-nav__devider my-6"></div>
              <ul>
                <li>
                  <Link
                      to={navigations.DASHBOARD}
                      className={`side-menu search-cusor ${getNavLinkClass(
                          navigations.DASHBOARD,
                          true
                      )}`}
                  >
                    <div className="side-menu__icon">
                      <Icons.Home/>
                    </div>
                    <div className="side-menu__title">Mes documents</div>
                  </Link>
                </li>

                <li>
                  <Link
                      to={navigations.SEARCH}
                      className={`side-menu search-cusor  ${getNavLinkClass(
                          navigations.SEARCH,
                          true
                      )}`}
                  >
                    <div className="side-menu__icon">
                      <Icons.Search/>
                    </div>
                    <div className="side-menu__title">Faire une recherche</div>
                  </Link>
                </li>

                <li>
                  <Link
                      to={navigations.SETTINGS}
                      className={`side-menu search-cusor  ${getNavLinkClass(
                          navigations.SETTINGS,
                          true
                      )}`}
                  >
                    <div className="side-menu__icon">
                      <Icons.Settings/>
                    </div>
                    <div className="side-menu__title">Paramètres</div>
                  </Link>
                </li>

                <li>
                  <Link
                      to={navigations.API_KEY}
                      className={`side-menu search-cusor  ${getNavLinkClass(
                          navigations.API_KEY,
                          true
                      )}`}
                  >
                    <div className="side-menu__icon">
                      <Icons.Key/>
                    </div>
                    <div className="side-menu__title">Clés API</div>
                  </Link>
                </li>

                <li>
                  <Link
                      to={navigations.LOG}
                      className={`side-menu search-cusor  ${getNavLinkClass(
                          navigations.LOG,
                          true
                      )}`}
                  >
                    <div className="side-menu__icon">
                      <Icons.PieChart/>
                    </div>
                    <div className="side-menu__title">Journal</div>
                  </Link>
                </li>


              </ul>
            </nav>

            <div className="content">
              <div className="top-bar">
                <div className="-intro-x breadcrumb mr-auto hidden sm:flex">
                  <Link to="" className="">
                    Mes documents
                  </Link>
                </div>

                <div className="intro-x dropdown w-8 h-8">
                  <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
                    <img
                        alt="Midone Tailwind HTML Admin Template"
                        src="/assets/dist/images/profile-1.jpg"
                    />
                  </div>
                  <div className="dropdown-box w-56">
                    <div className="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white">
                      <div className="p-4 border-b border-theme-40 dark:border-dark-3">
                        <div className="font-medium">Utilisateur</div>
                        <div className="text-xs text-theme-41 dark:text-gray-600">
                          En ligne
                        </div>
                      </div>
                      <div className="p-2">
                        <Link to={navigations.PROFILE}
                            className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        >
                          <Icons.User className='w-4 h-4 mr-2' />
                          Profile
                        </Link>
                        <Link to={navigations.PASSWORD}
                            className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        >
                          <Icons.Lock className='w-4 h-4 mr-2' />
                          Mot de passe
                        </Link>
                        <Link to={""} className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                          <Icons.HelpCircle className='w-4 h-4 mr-2' />
                          Aide
                        </Link>
                      </div>
                      <div className="p-2 border-t border-theme-40">
                        <Link to={navigations.LOGOUT} className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                          <Icons.ToggleRight className='w-4 h-4 mr-2' />Se déconnecter
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {children}
            </div>
          </div>
        </LayoutAdminWrapper>
      </HelmetProvider>
  );
};

export default LayoutAdmin;
