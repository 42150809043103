import React from 'react';

const LayoutLogin =({children})=>{
    return (<>
            <div className="login">
                {children}
            </div>

        </>

    )
}

export default LayoutLogin
